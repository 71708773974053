.custom-loader {
  width: 30px;
  height: 30px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side,#0000 60%,#000 41%);
  background: linear-gradient(0deg ,#B7C1CA 50%,#F4F6F8 0) center/1.5px 100%,
    linear-gradient(90deg,#657482 50%,#E4E6EB 0) center/100% 1.5px;
  background-repeat: no-repeat;
  animation: s3 5s infinite steps(6);
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.custom-loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes s3 {
  100% {
    transform: rotate(1turn)
  }
}