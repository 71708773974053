.arrows {
  width: 0.2px;
  height: 0.2px;
  border: 7px solid;
  border-color: black transparent transparent black;
  transform: rotate(-45deg);
}


.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite; 
          animation: slide 4s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s; 
    animation-delay: 1s;
}
.delay2 {
  -webkit-animation-delay: 2s; 
    animation-delay: 2s;
}
.delay3 {
  -webkit-animation-delay: 3s; 
    animation-delay: 3s;
}

@-webkit-keyframes slide {
    0% { opacity:0; transform: translateX(5vw); }	
   20% { opacity:1; transform: translateX(2vw); }	
   80% { opacity:1; transform: translateX(-5vw); }	
  100% { opacity:0; transform: translateX(-2vw); }	
}
@keyframes slide {
    0% { opacity:0; transform: translateX(2vw); }	
   20% { opacity:1; transform: translateX(1vw); }	
   80% { opacity:1; transform: translateX(-2vw); }	
  100% { opacity:0; transform: translateX(-1vw); }	
}
img {
  pointer-events: auto !important;
}